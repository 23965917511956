@import 'variables.scss';

.profile-pic
{
    width: calc(40vw);
    max-width: 300px;
    border-radius: 50%;
}

.about
{
    width: 100%;
    height: 800px;
    padding: 50px;
    background-color: $color-green-dark;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.about-text
{
    color: white;
    white-space: break-spaces;
}