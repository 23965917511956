@import 'variables.scss';

.footer
{
  flex-wrap: wrap;
  background-color: $color-green-darker;
  width: 100%;
}

a 
{
  margin: 15px;
  cursor: pointer;
}

.categories
{
  margin: 50px;
  color: white;;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}


.footer-line
{
  width: 100%;
  border-top: 15px solid $color-green-light;
}