.project-card {
    margin: 15px;
    width: 400px;
    height: 400px;
    border-radius: 20px;
    background-color: rgba(87, 217, 107, 0);
    position: relative;
    overflow: hidden;
    // display: flex;
    // margin-top: auto;
    // justify-content: space-between;
    // flex-direction: column;

    @media screen and (max-width: 950px) {
        width:90%;
    }    
    
    &:hover 
    {
        cursor: pointer;
    }
}

.project-card:hover .project {
    opacity: 1;
}

.project {
    padding-right: 25px;
    position: absolute;
    color: white;
    top: 100px;
    opacity: 0;
    padding-left: 25px;
    /* padding-bottom: 10px; */
    /* padding-top: 50px; */
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: linear-gradient(rgb(0 0 0 / 0%), #000000);
    transition: opacity 0.3s ease;
    height: 75%;
    width: 100%;
    justify-content: space-evenly;

    @media screen and (max-width: 950px) {
        opacity: 1;
    }    
}

.project-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.skills {
    display: flex;
    flex-wrap: wrap;
}

.skill {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
}
