.header {
  position: absolute;
    width: 100%;
    height: calc(5em - 2vw);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(4px);
    background-color: rgb(131 131 131 / 14%);
    z-index: 10;
    flex-wrap: wrap;

           
    @media screen and (max-width: 450px) {
      justify-content: center;
    }
  
    .nav {
      display: flex;
      gap: 1rem; // espace entre les boutons

        
      @media screen and (max-width: 450px) {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        height: 0px;
        width: 0px;
      }
  
      a {
        color: white;
        font-weight: 600;
        text-decoration: none;
        transition: text-decoration 0.3s ease;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
  
    .background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -10;
      background-size: cover;
      background-position: center;
    }
  }

  .icons
  {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    

  }

  .icon
  {
    margin: 10px;
    width: 25px;
    filter: invert(1);
    transition: all 0.2s ease;
  }

  
  .icon:hover
  {
    transition: all 0.2s ease;
    filter: invert(0)
  }

  .header-button {
    position: relative;
    padding: 0.25em 0.5em;
    font-size: 0.8em;
    border: none;
    outline: none;
    color: black;
    mix-blend-mode: screen;
    border-radius: 0.3em;
    background: white;
    transition: all 0.2s ease;

    &:hover
    {
      background: black;
      color: white;
      transition: all 0.2s ease;
    }
  }
  
