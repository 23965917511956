@import 'variables';
@tailwind base;
@tailwind components;
@tailwind utilities;

.app
{
  display: flex;
  flex-direction: column;
  align-items: center;
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

  background-color: $color-background-primary;
  color: $color-text-primary; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.section-title {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(45deg, $color-green-light, $color-green-dark 80%);
  margin: 30px;
  font-size: 50px;
  -webkit-background-clip: text;
  text-align: center;
  z-index: -5;

  @media screen and (max-width: 350px) {
    // margin: 10px;
    font-size: calc(10vw);
  }
}

