.projects-container
{
  margin-right: 25px;
  margin-left: 25px;
  width: 100%;
  justify-content: center;
  margin-bottom: 75px;
}

.projects-grid
{
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  
}