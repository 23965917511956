.wave-container {
    width: 100%;
    height: 250px; /* Fixed height */
    overflow: hidden;
    position: relative;
    margin-top: 200px;
}

.wave-container svg {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
}