@import 'variables';

.title
{
  // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: absolute;
  top: 0;
  display: flex;
  color: #ffffff;
  flex-direction: column;
  justify-content: center;
  z-index: 5;
  align-content: center;
  align-items: center;
  height: 800px;
  text-align: center;
}

.name
{
    color: #f4f4f4;
    font-size: 30px;
}

.greeting 
{
    color: #f4f4f4;
    font-size: 20px;
    
}
 
.profile-pic
{
}

.job
{
    margin-top: 40px;
    color: #ffffff;
    font-size: 25px;
}

/* This container ensures that the player takes up the entire viewport */
.title-wrapper {
    /* text-align: center; */
    /* max-width: 100%; */
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    /* width: 100vw; */
    /* padding-bottom: 56.25%; */
    // pointer-events: none;
    /* margin-left: auto; */
    /* margin-right: auto; */
    /* height: calc(100vh - 700px); */
    height: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column-reverse;
}

.video-container {
       /* width: 100%; */
    /* height: 0; */
    /* padding-bottom: 56.25%; */
    /* position: relative; */
    z-index: -5;
    height: 900px;
  }
  
  .video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: none;
    height: 900px;
    width: 100%;
  }


.job::after {
    content: "";
    width: 2px;
    height: 25px;
    line-height: -50px;
    background: #ffffff;
    display: inline-block;
    animation: cursor-blink 1.5s steps(2) infinite;
  }

@keyframes cursor-blink {
    0% {
      opacity: 0;
    }
  }

  .arrow {
    width: 300px;
    margin-top: 100px;
    transition: transform .7s ease-in-out;
    padding: 100px;
  }
  
  .arrow:hover {
    transform: scale(1.3) rotate(360deg);
  }


  