@import 'variables';

.jobs
{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}

.jobs-list
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.job-description
{
    border-radius: 5%;
    background-color: rgb(192, 202, 189);
    height: 200px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}


.job-logo
{
    width: 200px;
    height: 200px;
}