@import 'variables';

$primary-color: #2e7d32;
$secondary-color: #00796b;
$border-color: #b0e3c8;
$tag-background: #ffffff;
$tag-color: #44934a;
$text-color: #ffffff;
$secondary-text-color: #ffffff;
$description-color: #ffffff;

.experience-card {
  width: calc(50px + 60vw);
  max-width: 600px;
  background: $color-green-dark;
  border-radius: 10px;
  padding: 20px;
  color: $text-color;
  transition: all 0.3s ease;
  margin: auto;

  &.open {
    background: $color-green-darker;

    .experience-header {

      .toggle-button {
        color: $color-green-darker;
        rotate: 180deg;
        height: 25px;
        width: 25px;
        background-color: #ffffff;
        border-radius: 15%;
        display: inline-block;
        transition: all 0.3s ease;
      }
    }
  }

  .experience-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 1em;
      margin: 0;
      color: $text-color;
    }

    .title-company {
      font-size: 1em;
      color: $secondary-text-color;
      flex-grow: 1;
    }

    .duration {
      font-size: 1em;
      color: $secondary-text-color;
    }

    .toggle-button {
      background: none;
      border: none;
      font-size: 1em;
      cursor: pointer;
      color: $color-green-dark;
      margin-left: 15px;
      flex: none;

      height: 25px;
      width: 25px;
      background-color: #ffffff;
      border-radius: 50%;
      display: inline-block;
      transition: all 0.3s ease;

      &:focus {
        outline: none;
      }
    }
  }
}

.experience-body {
  margin-top: 5px;
  margin-bottom: 10px;
  width: calc(50px + 60vw);
  max-width: 600px;
  background: $color-green-dark;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0px;
  border-radius: 10px;
  transition: all 0.3s ease;
  color: $description-color;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease, padding 0.3s ease, margin-right 0.3s ease, margin-left 0.3s ease;

  &.open {
    max-height: 1000px; // arbitrary large number
    opacity: 1;
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px;

  }

  .location,
  .company-link,
  .description {
    margin: 10px 0;
  }

  // .description
  // {
  //   width: 100%;
  //   max-width: 1000px;
  // }

  .complementary-information {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: $border-color 3px inset;
  }

  .location {
    display: flex;
    align-items: center;

    i {
      margin-right: 5px;
    }
  }

  .company-link {
    color: $text-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .skills {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .skill-tag {
      background: $tag-background;
      border-radius: 5px;
      padding: 5px 10px;
      font-size: 0.9em;
      color: $tag-color;
    }
  }
}

/* Media query to hide the flex item when the screen size is smaller than 800px */
@media screen and (max-width: 800px) {
  .hide-on-small {
    display: none;
  }
}